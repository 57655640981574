import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Metas from "../components/Metas";
import MenuListItem from "../components/ListItems/MenuListItem";
import Breadcrumb from "../components/Breadcrumb";

const Portal = ({pageContext, data}) => {
    return (
        <Layout>
            <Metas title={pageContext.title}/>
            <section className="section page-content">
                <div className="container mx-auto px-4">
                    <Breadcrumb/>

                    <h1 className="title h1 text-primary">{pageContext.title}</h1>

                    <div className="columns grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                        {data.children.nodes.map((item, index) => (
                            <div key={index} className="column portal-link h-full">
                                <MenuListItem item={item} />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Portal

export const query = graphql`
    query portalPageElements ($parentId: String!) {
        children: allMenuLinkContentMenuLinkContent(
            filter: {
                enabled: {eq: true}, 
                menu_name: {eq: "main"}, 
                drupal_parent_menu_item: {
                    eq: $parentId
                }
            }
            sort: {fields: weight, order: ASC}
        ) {
            nodes {
                title
                drupal_parent_menu_item
                drupal_id
                link {
                    uri
                    options {
                        attributes {
                            data_has_icon
                            data_icon
                            data_text_invisible
                            target
                        }
                    }
                }
            }
        }
    }
`
