import React from "react";
import PropTypes from "prop-types";

import MenuLink from "../MenuLink";

const MenuListItem = ({ item }) => (
    <div className="menu-list-item h-full">
        <MenuLink internalId={item.link.uri} className="group block relative py-4 pl-4 pr-16 bg-primary text-white h-32 hover:bg-secondary">
            <div className="font-important font-semibold text-xl">{item.title}</div>
            <i className="fas fa-chevron-right absolute bottom-0 right-0 m-4 py-2 px-3 bg-secondary group-hover:bg-white group-hover:text-secondary rounded-full text-2xl">
                <span className="hidden">En lire plus</span>
            </i>
        </MenuLink>
    </div>
)

MenuListItem.propTypes = {
    item: PropTypes.object.isRequired,
}

export default MenuListItem;
